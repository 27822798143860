import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Contact from '../components/About/Contact';
import ArticleTemplate from './article-template';

import aboutImage from '../images/about.jpg';

const Article = ({ data }) => {
  return (
    <Layout title="News" description="Kontakt Tel:+387 37 689 113 Fax:+387 37 680 400 E-mail:gat@bih.net.ba" image={aboutImage}>
      <ArticleTemplate data={data} />
      <Contact />
    </Layout>
  )
};

Article.propTypes = {
  data: PropTypes.node,
};

Article.defaultProps = {
  data: null,
};

export const query = graphql`
  query GetSingleArticle($id: String) {
    article: strapiNews(id: { eq: $id }) {
        title_bs
        text_bs
        title_en
        text_en
        title_de
        text_de
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
}
`
export default Article;
