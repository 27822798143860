import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { LanguageContext } from '../components/Layout';

import styles from './article.module.scss';

const ArticleTemplate = ({ data }) => {
  const { article } = data;
  const { language } = useContext(LanguageContext);
  return (
    <>
      <div className={styles.imageContainer}>
        <BackgroundImage fluid={article.image.childImageSharp.fluid} className={styles.backgroundImage} />
      </div>
      <div className={styles.articleContainer}>
        <h2>{article[`title_${language}`]}</h2>
        <p>{article[`text_${language}`]}</p>
        <Image fluid={article.image.childImageSharp.fluid} className={styles.articleImage} />
      </div>
    </>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.node,
};

ArticleTemplate.defaultProps = {
  data: null,
};
export default ArticleTemplate;
